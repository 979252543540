<template>
    <v-textarea
        auto-grow counter rows="2" row-height="12"
        :rules="rules"
        :hide-details="true" dense outlined
        :label="label"
        :ref="name"
        :value="value"
        :placeholder="label"
        :reverse="reverse[format]"
        @input="$emit('changed', $event)"
        autocomplete="new-password"
    ></v-textarea>
</template>
<script>
export default {
    name: 'TextInput',
    props: ['label', 'format', 'name', 'value'],
    data: () => ({
        reverse: { 'default': false, 'number': true, 'date': false, 'decimal': true, 'euro': true, 'proz': true },
        rules: [v => v && v.length <= 250 || 'Max 250 characters'],
    }),
    mounted() {
        //console.log('TextInput[' + this.name + ']:', this.format, this.reverse[this.format])
    },
}
</script>